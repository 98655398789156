import React, { useState, useEffect } from 'react';
import './modalcookies.css';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const ModalCookies = ({ showModal }: { showModal: boolean | null }) => {
  const [isVisible, setIsVisible] = useState<boolean | null>(null);
  const [analyticsAccepted, setAnalyticsAccepted] = useState<boolean>(true);
  const [customizeMode, setCustomizeMode] = useState<boolean>(false);
  const [tempAnalyticsAccepted, setTempAnalyticsAccepted] = useState<boolean>(false); 
  
  useEffect(() => {    
    const cookiesAccepted = localStorage.getItem('cookies-accepted') === 'true';
    const analyticsAccepted = localStorage.getItem('analytics-accepted') !== 'false';

    setTempAnalyticsAccepted(analyticsAccepted);    
    setAnalyticsAccepted(analyticsAccepted);

    setIsVisible(showModal ?? !cookiesAccepted);

  }, [showModal]);

  useEffect(() => {
    configureAnalytics();
}, [analyticsAccepted]); 

  const handleCustomize = () => {
    setCustomizeMode(true);
  };

  const handleAnalyticsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempAnalyticsAccepted(event.target.checked);
  };

  const handleSave = () => {
    localStorage.setItem('analytics-accepted', tempAnalyticsAccepted ? 'true' : 'false');
    localStorage.setItem('cookies-accepted', 'true');
    setAnalyticsAccepted(tempAnalyticsAccepted);
    
    setIsVisible(false);
  };  

  const configureAnalytics = () => {
    if(analyticsAccepted){
      loadGoogleTagManager();
      addNoScriptTag();
    }
    else {
      removeAnalyticsScript();
    }
  };

  const removeAnalyticsScript = () => {

    document.getElementById("gtm-script")?.remove();
  
    document.getElementById("gtm-noscript")?.remove();

    document.querySelectorAll('script[src*="googletagmanager"]').forEach((script) => {
      script.remove();
    });
  
    document.querySelectorAll('iframe[src*="googletagmanager"]').forEach((iframe) => {
      iframe.remove();
    });
  
    if (window.dataLayer) {
      window.dataLayer = [];
    }
  };

  const loadGoogleTagManager = () => {
    if (document.getElementById("gtm-script")) return;
    
    const script = document.createElement("script");
    script.id = "gtm-script";
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TJ88RF7N');
    `;
  
    document.head.appendChild(script);
  };

  const addNoScriptTag = () => {
    if (document.getElementById("gtm-noscript")) return;
  
    const noscript = document.createElement("noscript");
    noscript.id = "gtm-noscript";
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TJ88RF7N"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
  
    document.body.prepend(noscript);
  };

  if (!isVisible) return null;

  return (
    <div className="modal-cookies">
      <div className="modal-content">
        <h2>Informacja o plikach cookies</h2>
        <p>
          Nasza strona internetowa używa plików cookies w celu zapewnienia jej prawidłowego działania, analizy ruchu oraz dostosowania treści do indywidualnych potrzeb użytkowników.
        </p>
        <p>
          Klikając "Akceptuję wszystko", wyrażasz zgodę na użycie wszystkich plików cookies, w tym analitycznych.
        </p>

        <div className="cookies-checkboxes">
          <div>
            <input
              type="checkbox"
              id="required-cookies"
              disabled
              checked
            />
            <label htmlFor="required-cookies">Wymagane pliki cookies</label>
          </div>

          <div>
            <input
              type="checkbox"
              id="analytics-cookies"
              checked={tempAnalyticsAccepted}
              onChange={handleAnalyticsChange}
              disabled={!customizeMode}
            />
            <label htmlFor="analytics-cookies">Pliki cookies analityczne</label>
          </div>
        </div>

        <p>
          Jeśli chcesz dowiedzieć się więcej, zapoznaj się z naszą <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Polityką Prywatności</a>.
        </p>

        <div className="buttons-container">

        {!customizeMode && (
        <button className="button customize-button" onClick={handleCustomize}>Dostosuję</button>
        )}
          {!customizeMode && (
            <button className="button accept-button" onClick={handleSave}>Akceptuję zaznaczone</button>
          )}

          {customizeMode && (
            <button className="button accept-button" onClick={handleSave}>Akceptuję</button>
          )} 
        </div>
      </div>
    </div>
  );
};

export default ModalCookies;
