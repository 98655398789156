import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import { storesListing, privacyPolicy, regulations, contact } from './../../dictionaries/navigation';
import ModalCookies from '../modalcookies';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Footer: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const openCookies = () => {
    setShowModal(true);
  };

  return (
    <div>
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <h4>Linki</h4>
          <ul>
            <li><Link to={storesListing} onClick={scrollToTop}>Sklepy</Link></li>
            <li><Link to={contact} onClick={scrollToTop}>Kontakt</Link></li>
            <li><Link to={privacyPolicy} onClick={scrollToTop}>Polityka Prywatności</Link></li>
            <li><Link to={regulations} onClick={scrollToTop}>Regulamin</Link></li>
            <li><Link to="#" onClick={openCookies}>Ustawienia cookies</Link></li>
          </ul>
        </div>
        <div className="footer-info">
          <p>&copy; {new Date().getFullYear()} Szafa Polska. Wszystkie prawa zastrzeżone.</p>
        </div>
      </div>

    </footer>
      {showModal && <ModalCookies showModal={showModal} />}
    </div>
  );
};

export default Footer;
