// export const server ="http://localhost:5000/api";
// export const maintenance ="http://localhost:5000/maintenance";
// export const baseUrl="http://localhost:5000/api";


//  export const server ="http://54.38.53.82:83/api";
//  export const maintenance ="http://54.38.53.82:83/maintenance";
//  export const baseUrl="http://54.38.53.82:83/api";

//  http://54.38.53.82:83

 export const baseUrl="https://szafapolska.pl";
 export const server ="https://szafapolska.pl/api";
 export const maintenance ="https://szafapolska.pl/maintenance";
// //export const server="http://kubernetes.docker.internal:30542";
