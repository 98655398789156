import React, { useState } from 'react';
import './contact.css';
import { Helmet } from 'react-helmet';
import { SendQuestion } from '../api/StoreService';
import { IContactForm } from '../types/IContactForm';

const Contact: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!email || !question) {
      setErrors(['Uzupełnij wymagane pola']);
      return;
    }

    if (!isValidEmail(email)) {
      setErrors(['Proszę podać poprawny adres e-mail']);
      return;
    }

    if (firstName.length > 50) {
      setErrors(['Imię nie może mieć więcej niż 50 znaków.']);
      return;
    }

    if (question.length > 1000) {
      setErrors(['Wiadomość nie może mieć więcej niż 1000 znaków.']);
      return;
    }

    const contactFormData: IContactForm = {
      firstName: firstName,
      email: email,
      question: question,
    };

    try {
      setIsLoading(true);
      await SendQuestion(contactFormData);

      setIsSubmitted(true);
      setErrors([]);

      setFirstName('');
      setEmail('');
      setQuestion('');
    } catch (error: any) {
      if (error.response && error.response.data && Array.isArray(error.response.data.errors)) {
        setErrors(error.response.data.errors);
      } else {
        setErrors(['Wystąpił błąd podczas wysyłki zapytania. Spróbuj ponownie.']);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="pre-contact-container">
    <div className="contact-container">
      <Helmet>
        <title>Kontakt - Polskie Marki</title>
        <meta name="description" content="Skontaktuj się ze mną." />
      </Helmet>

      <h1>Skontaktuj się ze mną!</h1>
      <div className="contact-content">
        <div className="text-container">
          <p>
            Jeśli chcesz:
            <ul>
              <li>dodać nowy sklep do katalogu,</li>
              <li>zaktualizować dane dotyczące istniejącego sklepu,</li>
              <li>usunąć sklep z naszej listy,</li>
              <li>podzielić się sugestią</li>
            </ul>
            <p>jestem do Twojej dyspozycji!
              <p>Wystarczy, że wypełnisz formularz kontaktowy lub napiszesz bezpośrednio na email: kontakt@szafapolska.pl, a postaram się odpowiedzieć jak najszybciej.</p>
            Dziękuję za Twoją pomoc w utrzymywaniu aktualnych informacji i wspieraniu lokalnych marek!
                      </p> </p>
        </div>

        <div className="form-container">
          <form onSubmit={handleSubmit} className="contact-form">
            {errors.length > 0 && (
              <div className="error-message">
                {errors.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </div>
            )}
            {isSubmitted && (
              <div className="success-message">Dziękuję za kontakt! Odpowiem jak najszybciej.</div>
            )}
            <div className="form-group">
              <label htmlFor="firstName">Imię:</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                maxLength={50}
              />
              <small>{firstName.length}/50</small>
            </div>
            <div className="form-group">
              <label htmlFor="email">*Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="question">*Wiadomość:</label>
              <textarea
                id="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                maxLength={1000}
                required
              />
              <small>{question.length}/1000</small>
            </div>
            <button className="send-button" type="submit" disabled={isLoading}>Wyślij</button>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Contact;
