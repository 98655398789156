import React from 'react';
import { Route, Routes } from 'react-router-dom';
import StoreList from './components/Stores/StoreList';
import StoreDetails from './components/Stores/StoreDetails';
import NavBar from './components/navbar';
import Footer from './components/footer/footer';
import './App.css';
import Contact from './components/contact';
import PrivacyPolicy from './components/footer/privacypolicy';
import Regulations from './components/footer/regulations';
import { Home } from './components/main/home';
import StoreManagerEdit from './maintenance/storemanager.edit';
import {home, storesListing, privacyPolicy, regulations, contact} from './dictionaries/navigation';
import StoreManagerListing from './maintenance/StoreManager.listing';
import NotFound from './components/NotFound';
import Seo from './components/seo/seo';
import ModalCookies from './components/modalcookies';

const App: React.FC = () => {
  
  return (
    <>
    
      <NavBar />
          <ModalCookies showModal={null} />
      <div className="main-container">
        <Routes>
          <Route path={home} element={<Home />} />
          <Route path={`${storesListing}`} element={<StoreList />} />
          <Route path={`${storesListing}/filtry/:clothesTypeSegment?/:genderSegment?/:tagsSegment?`} element={<StoreList />} />
          <Route path={contact} element={<Contact />} />
          <Route path={privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={regulations} element={<Regulations />} />
        
          <Route path="*" element={<NotFound />} />

          {/* <Route path="/stores/:urlName" element={<StoreDetails />} />
          <Route path="/manage/add" element={<StoreManagerEdit />} />
          <Route path="/manage/:storeId" element={<StoreManagerEdit />} />
          <Route path="/manage" element={<StoreManagerListing />} /> */}

        </Routes>
      </div>
      <Seo />
      <Footer />
    </>
  );
};

export default App;
