import './regulations.css';

const PrivacyPolicy = () => {
    return (
        <div className="container-policy">
            <h1>Polityka Prywatności</h1>

            <h2>1. Wstęp</h2>
            <p>1.1. Niniejsza Polityka Prywatności wyjaśnia, w jaki sposób zbieramy, przechowujemy, przetwarzamy i chronimy dane osobowe użytkowników Strony „Szafa Polska”.</p>
            <p>1.2. Właścicielem i administratorem Strony jest osoba prywatna, z którą można skontaktować się poprzez formularz kontaktowy dostępny na stronie lub pod adresem e-mail: kontakt@szafapolska.pl.</p>

            <h2>2. Zbieranie danych osobowych</h2>
            <p>2.1. Zbieramy dane osobowe użytkowników wyłącznie w celu realizacji funkcji dostępnych na Stronie, w tym poprzez formularz kontaktowy oraz przez narzędzia analityczne.</p>
            <p>2.2. Dane, które możemy zbierać, to:</p>
            <ul>
                <li>Imię i nazwisko,</li>
                <li>Adres e-mail,</li>
                <li>Numer telefonu (opcjonalnie),</li>
                <li>Adres IP,</li>
                <li>Informacje o przeglądarce, systemie operacyjnym i zachowaniu na stronie (np. czas spędzony na stronie, odwiedzane podstrony).</li>
            </ul>

            <h2>3. Przetwarzanie danych osobowych</h2>
            <p>3.1. Przetwarzamy dane osobowe w celu:
            <ul>
                <li>Odpowiedzi na zapytania użytkowników,</li>
                <li>Realizacji funkcji Strony,</li>
                <li>Analizy ruchu na stronie przy pomocy narzędzi takich jak Google Analytics.</li>
                </ul>
            </p>
            <p>3.2. Użytkownik ma prawo do dostępu do swoich danych osobowych, ich poprawiania, usunięcia oraz ograniczenia przetwarzania.</p>

            <h2>3.3. Okres przechowywania danych</h2>
            <p>Dane osobowe będą przechowywane przez okres nie dłuższy niż jest to konieczne do realizacji celów, dla których zostały zebrane. Czas przechowywania zależy od rodzaju danych oraz celu przetwarzania.</p>

            <h2>4. Podstawa prawna przetwarzania danych</h2>
            <p>4.1. Przetwarzanie danych odbywa się na podstawie:</p>
            <ul>
                <li>Zgody użytkownika (art. 6 ust. 1 lit. a RODO) w przypadku korzystania z plików cookie do celów analitycznych,</li>
                <li>Prawnie uzasadnionego interesu administratora (art. 6 ust. 1 lit. f RODO) w przypadku analizy ruchu na stronie oraz zapewnienia jej bezpieczeństwa.</li>
            </ul>

            <h2>5. Ochrona danych osobowych</h2>
            <p>5.1. Dokładamy wszelkich starań, aby zapewnić bezpieczeństwo danych osobowych użytkowników, w tym stosując odpowiednie środki techniczne i organizacyjne.</p>

            <h2>6. Udostępnianie danych osobowych</h2>
            <p>6.1. Nie sprzedajemy ani nie udostępniamy danych osobowych użytkowników osobom trzecim bez ich zgody, chyba że wymaga tego prawo.</p>
            <h2>7. Zmiany w Polityce Prywatności</h2>
            <p>
                7.1. Zastrzegamy sobie prawo do wprowadzania zmian w Polityce Prywatności. Zmiany wchodzą w życie z chwilą ich opublikowania na Stronie.
            </p>
            <h2>8. Pliki cookie</h2>
            <p>8.1. Strona korzysta z plików cookie w celu poprawy jej funkcjonalności oraz analizy ruchu na stronie. Przed ich załadowaniem użytkownik musi wyrazić zgodę na wykorzystywanie plików cookie.</p>
            <p>8.2. Pliki cookie mogą być wykorzystywane do zbierania informacji o zachowaniu użytkowników na stronie, w tym przez narzędzia takie jak Google Analytics.</p>
            <p>8.3. Użytkownicy mogą zarządzać swoimi preferencjami dotyczącymi plików cookie w ustawieniach przeglądarki lub poprzez narzędzie dostępne na stronie. Możesz w każdej chwili zmienić swoje preferencje w tej kwestii.</p>

            <h2>9. Zewnętrzne skrypty</h2>
            <p>9.1. Nasza strona korzysta z usług takich jak Google Analytics, które mogą zbierać dane na temat adresu IP użytkownika, rodzaju przeglądarki oraz zachowań użytkowników na stronie. Informacje te mogą być wykorzystywane w celach analitycznych.</p>
            <p>9.2. Google Analytics zbiera dane anonimowo, ale użytkownicy mogą zrezygnować z jego działania, instalując wtyczkę Google Analytics Opt-out Browser Add-on: 
            <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
            <p>9.3. Szczegóły dotyczące przetwarzania danych przez Google Analytics można znaleźć w polityce prywatności Google: 
            <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>

            <h2>10. Zmiany w Polityce Prywatności</h2>
            <p>10.1. Zastrzegamy sobie prawo do wprowadzania zmian w Polityce Prywatności. Zmiany wchodzą w życie z chwilą ich opublikowania na Stronie.</p>

            <h2>11. Kontakt z administratorem danych</h2>
            <p>11.1. W razie pytań dotyczących Polityki Prywatności lub przetwarzania danych osobowych, prosimy o kontakt pod adresem e-mail: kontakt@szafapolska.pl.</p>
            <p>11.2. Użytkownik ma również prawo do wniesienia skargi do organu nadzorczego, którym w Polsce jest Prezes Urzędu Ochrony Danych Osobowych (PUODO).</p>
        </div>
    );
};

export default PrivacyPolicy;
